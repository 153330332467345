import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import DiamondRingImg from '../../../../Assets/images/unique-holiday-highlight.gif'
const HolidayHighlight = () => {
    return (
        <section className='vanice-DiamondRing holiday_highlight_section'>
            <Container>
                <Row>
                    <Col md={5} className="order-md-1 order-2 ">
                        <Image className='img-fluid' alt='Diamond-Ring-Img' src={DiamondRingImg} />
                    </Col>
                    <Col md={7} className="order-md-2 order-1 mt-md-0 mt-4">
                        <div className='WordStyle'>H</div>
                        <div className='textBox'>
                            {/* <h5 className='vanice-DiamondRing-subtitle'>Holiday Jewelry Highlights</h5> */}
                            <h2 className='vanice-DiamondRing-title'>Holiday Jewelry Highlights</h2>
                            <p className='vanice-DiamondRing-text'>Discover timeless elegance with Unique Jewels’ "Season of Splendor" Holiday Gift Guide. Featuring dazzling diamond rings and exquisite designs, our collection is crafted to celebrate love and luxury. Perfect for the season’s festivities, each piece radiates sophistication and charm, making it an unforgettable gift for your loved ones.</p>
                            {/* <div className='vanice-DiamondRing-btn'>
                                <Link to='/ringsettings'>Start with a setting</Link>
                                <Link to='/diamonds'>Start with a diamond</Link>
                            </div> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default HolidayHighlight